import { NodeViewWrapper } from '@tiptap/react';
import React from 'react';

import { getTweetIdFromUrl } from '@zealy/utils';

import { TweetComponent } from './TweetComponent';

export const TweetNodeView = ({ node }: { node: any }) => {
  const url = node.attrs?.src;
  const tweetId = node.attrs.tweetId ?? (url && getTweetIdFromUrl(url));

  if (!tweetId) {
    return null;
  }

  return (
    <NodeViewWrapper>
      <TweetComponent tweetId={tweetId} />
    </NodeViewWrapper>
  );
};
