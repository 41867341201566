'use client';

import React from 'react';
import { EmbeddedTweet, TweetNotFound, TweetSkeleton, useTweet } from 'react-tweet';

interface TweetComponentProps {
  tweetId: string;
}

export const TweetComponent: React.FC<TweetComponentProps> = ({ tweetId }) => {
  const { data, error, isLoading } = useTweet(tweetId);

  if (!tweetId) return null;

  return isLoading ? (
    <TweetSkeleton />
  ) : error || !data ? (
    <TweetNotFound error={error} />
  ) : (
    <EmbeddedTweet tweet={data} />
  );
};
