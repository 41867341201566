import { ReactNodeViewRenderer } from '@tiptap/react';
import { mergeAttributes, Node, nodePasteRule } from '@tiptap/core';

import { getTweetIdFromUrl } from '@zealy/utils';

import { TweetNodeView } from './TweetNodeView';

const TWEET_REGEX_GLOBAL =
  /^((https?):\/\/)?(www.)?(twitter\.com|x\.com)(\/@?(\w){1,15})\/status\/[0-9]{19}/g;

export const Tweet = Node.create({
  name: 'tweet',
  addOptions() {
    return {
      addPasteHandler: true,
      HTMLAttributes: {},
      inline: false,
    };
  },
  inline() {
    return this.options.inline;
  },
  group() {
    return this.options.inline ? 'inline' : 'block';
  },
  draggable: true,
  addAttributes() {
    return {
      tweetId: {
        default: null,
      },
      src: {
        default: null,
      },
    };
  },
  parseHTML() {
    return [
      {
        tag: 'div[data-tweet]',
      },
    ];
  },
  addPasteRules() {
    return [
      nodePasteRule({
        find: TWEET_REGEX_GLOBAL,
        type: this.type,
        getAttributes: (match: { input: string }) => {
          return {
            src: match.input,
            tweetId: getTweetIdFromUrl(match.input),
          };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }: { HTMLAttributes: Record<string, unknown> }) {
    return [
      'div',
      mergeAttributes(
        this.options.HTMLAttributes,
        { 'data-tweet-id': HTMLAttributes.tweetId },
        HTMLAttributes,
      ),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(TweetNodeView);
  },
});

export { TweetComponent } from './TweetComponent';
export { TweetNodeView } from './TweetNodeView';
export default Tweet;
